:root {
  --header-height: 42px;
  --key-height: 52px;
  --key-width: 52px;
}

$font-xl: 140%;
$font-l: 100%;
$font-m: 62.5%;
$font-s: 58%;
$font-xs: 40%;

$color-gray: gray;
$color-gray-100: #f5f5f5;
$color-gray-200: #eeeeee;
$color-gray-300: #e0e0e0;
$color-gray-400: #dbdbdb;
$color-gray-500: #9e9e9e;
$color-gray-600: #757575;

$primary: #3f51b5;
$primary-light: #7986cb;
$primary-pale: #c5cae9;
$success: $primary;
$error: #f44336;
$warning: #ff9800;
$info: #8bc34a;

$space-xl: 32px;
$space-l: 16px;
$space-m: 8px;
$space-s: 4px;
$space-xs: 2px;
