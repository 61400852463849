@import '../../../variables';

.definition-detail-wrapper {
  position: fixed;
  width: 100%;
  //max-width: 960px;
  margin-top: calc(var(--key-height));
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.definition-detail-container {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 52px - 26px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.definition-detail-card {
  width: 100%;
  height: 100%;
  padding-top: 16px;
}

.definition-detail-form-container {
  display: flex;
  flex-direction: row;
}

.definition-detail-upload-form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: none;
  width: 400px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.definition-detail-upload-msg {
  font-weight: bold;
  font-size: 140%;
}

.definition-detail-alert {
  margin: 0 32px 32px 32px;
}

.definition-detail-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 0 32px;

  &-row {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: $space-s;
    }
  }

  &-notice {
    font-size: 80%;
    color: $color-gray;
    margin-top: $space-m;
  }
}

.edit-keyboard-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-keyboard-stepper {
  margin-top: $space-l;
  margin-bottom: $space-l;
}
