@import 'src/variables';

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  background-color: white;
  padding: $space-s $space-m;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
  z-index: 1;

  a {
    color: $primary-light;
    margin-right: 16px;

    &:last-child {
      margin-right: auto;
    }
  }
  .dev-team {
    text-align: start;
  }
  .footer-contents {
    text-align: center;
  }
  .build-number {
    text-align: end;
    color: $color-gray-400;
  }
}
