@import '../../../variables';

.organization-detail-wrapper {
  position: fixed;
  width: 100%;
  margin-top: calc(var(--key-height));
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-detail-container {
  width: 100%;
  height: calc(100vh - var(--key-height) - 26px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-detail-card {
  width: 100%;
  max-width: 960px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.organization-detail-alert {
  margin: 0 32px 32px 32px;
}

.organization-detail-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.organization-detail-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 0 32px;

  &-row {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.organization-detail-form-member-form {
  display: flex;
  margin: auto 16px auto 16px;

  &-email {
    flex: auto;
    margin-right: 16px !important;
  }

  &-button {
  }
}
