.top {
  &-wrapper {
    position: fixed;
    width: 100%;
    margin-top: calc(var(--key-height));
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-container {
    width: 100%;
    height: calc(100vh - 52px - 26px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-buttons {
    margin: 32px auto auto auto;

    & a {
      &:first-child {
        margin-right: 32px;
      }
    }
  }
}
