@keyframes click-key {
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }

  7% {
    opacity: 0.86;
    transform: scale(0.95, 0.95);
  }

  20% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.logo-m {
  transform-origin: 39% 50%;
  animation: click-key 2.1s linear 0s infinite;
}

.logo-a {
  transform-origin: 64% 50%;
  animation: click-key 2.1s linear 0.7s infinite;
}

.logo-p {
  transform-origin: 89% 50%;
  animation: click-key 2.1s linear 1.4s infinite;
}
